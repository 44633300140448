import React from 'react'
import Layout from "../components/App/Layout"
import NavbarStaging from "../components/App/NavbarStaging"
import Footer from "../components/App/Footer"
import BlogCard from '../components/BlogContent/BlogCard'

const Blog = () => {
    return (
        <Layout>
            <NavbarStaging />
            <BlogCard />
            <Footer />
        </Layout>
    );
}

export default Blog;